import { Link } from "@StarberryUtils";
import * as React from "react"
import { useMatch } from "@reach/router"
import HTMLReactParser from 'html-react-parser';
import Google from "../../../images/google-reviews.png"
import { GetURL } from "../../common/site/functions";

import PlayVideo from "../../Play/custom-video";
import { Button, Container, Form } from 'react-bootstrap';
// styles
//import ScrollAnimation from "react-animate-on-scroll";
import "./Banner.scss"
// markup

const PlainHeader = (props) => {

  let url_1 = '';
  let url_2 = ''
  if (props.cta_1_link) {
    url_1 = GetURL(props.cta_1_link.id)
  }
  if (props.cta_1_link) {
    url_2 = GetURL(props.cta_2_link.id)
  }

  return (
    <React.Fragment>
      <div className="main-banner plain-header plain-header-content-padd">
        <div className="main-banner-content">
          <Container className="pad-50">
            <div className="banner-text">
              {props.content &&
                <div animateIn='fadeInUp' animateOnce>
                  {HTMLReactParser(props.content.replace(/<br\s*\/?>/gi, ''))}
                </div>
              }
              {props.search === 'Buy' &&
                <Form className="banner-search">
                  <Form.Group className="d-flex align-items-center">
                    <Link to="#" className="search-btn"><i className="search-icon"></i></Link>
                    <Form.Control type="text" placeholder="Project name or area" />
                    <Button className="d-none d-md-inline-block buy" variant="primary" type="submit">
                      <span>Buy</span>
                    </Button>
                  </Form.Group>
                  <div className="buttons-group d-md-none">
                    <Button variant="primary">Buy</Button>{' '}
                  </div>
                </Form>
              }
              {props.search === 'Buy_and_Rent' &&
                <>
                  <p>817 Properties Available To Rent or Buy</p>
                  <Form className="banner-search">
                    <Form.Group className="d-flex align-items-center">
                      <Link to="#" className="search-btn"><i className="search-icon"></i></Link>
                      <Form.Control type="text" placeholder="Project name or area" />
                      <Button className="d-none d-md-inline-block buy" variant="primary" type="submit">
                        <span>Buy</span>
                      </Button>
                      <Button className="d-none d-md-inline-block rent" variant="primary" type="submit">
                        <span>Rent</span>
                      </Button>
                    </Form.Group>
                    <div className="buttons-group d-md-none">
                      <Button variant="primary">Buy</Button>{' '}
                      <Button variant="primary">Rent</Button>{' '}
                    </div>
                  </Form>
                </>
              }

              {props.info &&
                <div className="connect">
                  <span className="connect-us">
                    <a className="call-us">
                      <i className="icon-call">
                      </i>
                    </a>
                  </span>
                  <span className="tel">
                    {HTMLReactParser(props.info.replace(/\n/g, '<br />'))}
                  </span>
                </div>
              }

              <div className="banner-btns">
                {props.cta_1 &&
                  <Link to={`/${url_1}`} className="btn btn-primary">{props.cta_1}</Link>
                }
                {props.cta_2 &&
                  <Link to={`/${url_2}`} className="btn btn-outline btn-link">{props.cta_2}</Link>
                }
              </div>


              {props.rating === true &&
                <div className="google-rating">
                  <div className="img">
                    <img loading="lazy" src={Google} alt="google-review - Dacha" />
                  </div>
                  <div className="rating-content">
                    <p className="rating">4.8/5 Rating from 148 Reviews</p>
                    {props.page !== 'Customer Reviews' &&
                      <p className="more">View more reviews</p>
                    }
                  </div>
                </div>
              }

            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PlainHeader